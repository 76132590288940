import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import ArrowDown from 'img/down.inline.svg'

const Wrapper = styled.section<{ bgColor?: string }>`
  background-color: ${(props) =>
    props.bgColor === 'light' ? props.theme.color.background : 'transparent'};
  margin-top: -3rem;
`

const Item = styled(motion.div)`
  border-bottom: 2px solid ${({ theme }) => theme.color.background};

  & button {
    font-size: ${({ theme }) => theme.font.size.big};
    color: ${({ theme }) => theme.color.primary};
  }

  & p {
    color: ${({ theme }) => theme.color.dark};
  }

  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.color.primary};
  }
`

interface AccordionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Accordion
}

const Accordion: React.FC<AccordionProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(0)

  return (
    <Wrapper className="mb-5 mt-n5">
      <div className="container pb-5 px-lg-0">
        <div className="row justify-content-center">
          <div className="col-lg-8 ">
            <div
              className={`row ${
                fields.styletype === 'right'
                  ? 'justify-content-end'
                  : 'justify-content-start'
              }`}
            >
              <div className="col-lg-8 px-lg-0">
                {fields?.item?.map((item, index) => {
                  const isOpen: boolean = index === currentIndex

                  return (
                    <Item className="py-4">
                      <button
                        type="button"
                        onClick={() => {
                          if (isOpen) {
                            setCurrentIndex(null)
                          }
                          if (!isOpen) {
                            setCurrentIndex(index)
                          }
                        }}
                        className="d-flex justify-content-between w-100"
                      >
                        <div className="w-100 text-start">
                          <span>{index + 1}. </span>
                          <span>{item?.title}</span>
                        </div>
                        <div>
                          <ArrowDown />
                        </div>
                      </button>
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                              open: {
                                opacity: 1,
                                height: 'auto',
                                overflow: 'hidden',
                              },
                              collapsed: {
                                opacity: 0,
                                height: 0,
                                overflow: 'hidden',
                              },
                            }}
                            transition={{
                              duration: 0.8,
                              ease: [0.04, 0.62, 0.23, 0.98],
                            }}
                          >
                            <ParseContent
                              content={item?.description}
                              className="py-4"
                            />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Item>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Accordion
